import React, { Component } from "react"
import anime from "animejs/lib/anime.es.js"
import Image from "gatsby-image"

import styles from "./page-hero.module.scss"

class PageHero extends Component {
  constructor (props) {
    super(props);
  }
  componentDidMount() {
    //console.log('Color1', this.props.kleur)
    const tl = anime
      .timeline({
        easing: "easeOutCubic",
        duration: 1500,
      })
      .add(
        {
          targets: this.refs.hero.querySelector(`.${styles.image}`),
          opacity: [0, 1],
          duration: 400,
        },
        100
      )
      .add({
        targets: this.refs.hero.querySelector(`.${styles.background}`),
        height: ["0%", "100%"],
        duration: 400,
      })
      // .add(
      //   {
      //     targets: this.refs.hero.querySelector(`.${styles.background}`),
      //     filter: ["grayscale(1)", "grayscale(1)"],
      //     duration: 400,
      //   },
      //   "-=200"
      // )
      .add(
        {
          targets: this.refs.hero.querySelector(`.${styles.reveal}`),
          height: ["0%", "100%"],
          duration: 400,
          delay: 200,
        },
        "-=200"
      )
      .add(
        {
          targets: this.refs.hero.querySelectorAll(
            `.${styles.title} span > span`
          ),
          translateY: ["172px", "0px"],
          duration: 1200,
          easing: "easeInOutExpo",
          delay: anime.stagger(100),
        },
        this.props.details.image.src !== null ? "-=500" : 0
      )
      .add(
        {
          targets: this.refs.hero.querySelector(`.${styles.line}`),
          width: ["0%", "100%"],
          background: ["#256eff", this.props.kleur],
          duration: 400,
          easing: "easeInOutExpo",
        },
        this.props.details.image.src !== null ? "-=800" : 300
      )
      .add(
        {
          targets: this.refs.hero.querySelectorAll(
            `.${styles.subtitle} span:first-child`
          ),
          translateY: ["104px", "0px"],
          duration: 800,
          easing: "easeInOutExpo",
          delay: anime.stagger(100),
        },
        this.props.details.image.src !== null ? "-=600" : 200
      )
  }

  render() {
      const { title, subtitle, image } = this.props.details
      const { type } = this.props
      const color = this.props.kleur
    // console.log("image", image)
    //console.log("color", color)
    let featuredImage = false

    if (image.src !== null && image.src.localFile) {
      featuredImage = image.src.localFile.childImageSharp.fluid
    }
    return (
      <section
        className={[
          styles.hero,
          type ? styles[`${type}`] : null,
          image.src === null ? styles[`noImage`] : null,
        ].join(" ")}
        ref="hero"
      >
        <div className={[styles.wrapper, "wrapper"].join(" ")}>
          {featuredImage && (
                    <div className={styles.image}>
                        <span className={styles.reveal} style={{ backgroundColor: color }} />
              <Image
                fluid={featuredImage}
                className={styles.background}
                alt={image.alt}
                title={image.title}
              />
            </div>
          )}
          <header className={styles.header}>
            <h1 className={styles.title}>
              {title.map((row, i) => (
                <span
                  key={i}
                  className={row.invert === true ? styles.invert : null}
                >
                  <span>{row.text}</span>
                </span>
              ))}
            </h1>
            {subtitle && (
              <span className={styles.subtitle}>
                <span>{subtitle}</span>
                <span className={styles.line} />
              </span>
            )}
          </header>
        </div>
      </section>
    )
  }
}

export default PageHero
